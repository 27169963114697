
import { defineComponent, ref, toRefs } from 'vue';
import { DatePicker } from 'v-calendar';
import Multiselect from '@vueform/multiselect';
import useLoggedInUser from '@/composables/use-logged-in-user';
import EventResource, { IEventModel } from '@/resources/EventResource';
import UserResource from '@/resources/UserResource';
import { YesNoEnum } from '@/enums/YesNoEnum';
import { UserRoleEnum } from '@/enums/UserRoleEnum';
import { ProductReservationStatusEnum } from '@/enums/ProductReservationStatusEnum';

export default defineComponent({
    components: { DatePicker, Multiselect },
    props: ['filters'],

    setup(props) {
        const { filters } = toRefs(props);
        const eventOptions = ref([]);
        const eventFilters = ref([]);
        const userOptions = ref([]);
        const showArchivedEvents = ref(false);
        const { getLoggedInUser } = useLoggedInUser();

        EventResource.query().then((response) => {
            eventOptions.value = response.data.data;

            eventFilters.value = eventOptions.value.filter((event: IEventModel) => {
                return !event.is_archived;
            });
        });

        UserResource.query().then((response) => {
            userOptions.value = response.data.data;
        });

        function toggleArchivedEvents() {
            showArchivedEvents.value = !showArchivedEvents.value;

            filters.value.event_id = undefined;

            eventFilters.value = eventOptions.value.filter((event: IEventModel) => {
                if (showArchivedEvents.value) {
                    return event.is_archived;
                }

                return !event.is_archived;
            });
        }

        return {
            form: filters,
            loggedInUser: getLoggedInUser(),
            UserRoleEnum,
            YesNoEnum,
            ProductReservationStatusEnum,
            eventOptions,
            eventFilters,
            userOptions,
            toggleArchivedEvents,
        }
    }
});
