export const YesNoEnum = {
    NO: 0,
    YES: 1,
    toOptions: () => {
        return [
            {
                value: YesNoEnum.NO,
                label: 'general.NO'
            },
            {
                value: YesNoEnum.YES,
                label: 'general.YES'
            }
        ]
    }
};
