import { computed, reactive, watch } from 'vue';

interface IFilters {
    [key: string]: {
        [key: string]: string
    };
}

export default function useFilters(key: string, initialFilters: any) {
    const state = reactive({
        filters: <IFilters>{},
    });
    const defaultFilters = Object.assign({}, initialFilters);

    const cacheKey = `filters-${key}`;
    state.filters[cacheKey] = initialFilters;

    const cachedFilters = window.localStorage.getItem(cacheKey);
    if (cachedFilters) {
        Object.assign(state.filters[cacheKey], JSON.parse(cachedFilters));
    }

    const filters = computed(() => {
        return state.filters[cacheKey];
    });

    watch(state.filters[cacheKey], (filters) => {
        window.localStorage.setItem(cacheKey, JSON.stringify(filters));
    });

    const clearFilters = () => {
        Object.assign(state.filters[cacheKey], defaultFilters);
        window.localStorage.removeItem(cacheKey);
    }

    return {
        filters,
        clearFilters
    }
}
