import ApiResource, { IModel } from "@/resources/ApiResource";

class UserResource extends ApiResource {}

export default new UserResource("/user");

export interface UserModel extends IModel {
  name: string;
  email: string;
  phone?: string;
}
