import ApiResource, { ApiQuery, IModel } from "@/resources/ApiResource";
import useAxios from "@/composables/use-axios";

const { apiClient } = useAxios();

class ProductReservationResource extends ApiResource {
  async exportToExcel(query: ApiQuery) {
    return apiClient()
      .get(`${this.uri}/export`, { params: query })
      .then((response) => {
        const apiBaseUrl = process.env.VUE_APP_API_URL.replace("/api", "/");
        const filePath = `${apiBaseUrl}/${response.data.filePath}`;

        window.location.replace(filePath);
      });
  }
  async exportToPdf(query: ApiQuery) {
    return apiClient()
      .get(`${this.uri}/export-pdf`, { params: query })
      .then((response) => {
        const apiBaseUrl = process.env.VUE_APP_API_URL.replace("/api", "/");
        const filePath = `${apiBaseUrl}/${response.data.filePath}`;

        window.open(filePath);
      });
  }

  async confirmReservation(reservationId: number) {
    return apiClient().put(`product-reservation/confirm/${reservationId}`);
  }
}

export default new ProductReservationResource("/product-reservation");

export interface ProductReservationModel extends IModel {
  id: number;
  event_id: number;
  user_id: number;
  product_id: number;
  status_id: number;
  is_overbooked: boolean;
  comment: string;
  reserved_qty: number;
  overbooked_qty: number;
  row_total: number;
  reserved_from: string;
  reserved_to: string;
}
