
import { defineComponent } from 'vue';
import i18n from '@/i18n';
import { ProductReservationStatusEnum } from '@/enums/ProductReservationStatusEnum';
const { t } = i18n.global;

export default defineComponent({
    props: ['reservation'],
    setup(props) {
        const statusLabel = {
            label: '',
            className: ''
        };

        switch (props.reservation.status_id) {
            case ProductReservationStatusEnum.STATUS_CONFIRMED:
                statusLabel.label = t('product_reservation.STATUS_CONFIRMED');
                statusLabel.className = 'is-success';
                break;
            case ProductReservationStatusEnum.STATUS_UNCONFIRMED:
                statusLabel.label = t('product_reservation.STATUS_UNCONFIRMED');
                statusLabel.className = 'is-warning';
                break;
        }

        return {
            statusLabel
        }
    },
});
