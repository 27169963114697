export const ProductReservationStatusEnum = {
    STATUS_UNCONFIRMED: 0,
    STATUS_CONFIRMED: 1,
    toOptions: () => {
        return [
            {
                value: ProductReservationStatusEnum.STATUS_UNCONFIRMED,
                label: 'product_reservation.STATUS_UNCONFIRMED'
            },
            {
                value: ProductReservationStatusEnum.STATUS_CONFIRMED,
                label: 'product_reservation.STATUS_CONFIRMED'
            }
        ]
    }
};
