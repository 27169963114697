
import { defineComponent, onMounted, reactive, Ref, toRefs, watch } from "vue";
import ProductReservationResource, {
  ProductReservationModel,
} from "@/resources/ProductReservationResource";
import Pagination from "@/components/Pagination/Pagination.vue";
import TableToolbar from "@/components/TableToolbar.vue";
import StatusLabel from "@/components/ProductReservation/StatusLabel.vue";
import TableFilters from "@/components/TableFilters.vue";
import Filters from "@/components/ProductReservation/Filters.vue";
import Categories from "@/components/Product/Categories.vue";
import useList from "@/composables/use-list";
import useFilters from "@/composables/use-filters";
import { ProductReservationStatusEnum } from "@/enums/ProductReservationStatusEnum";
import useLoggedInUser from "@/composables/use-logged-in-user";
import { UserRoleEnum } from "@/enums/UserRoleEnum";
import i18n from "@/i18n";

export default defineComponent({
  components: {
    Categories,
    Filters,
    TableFilters,
    StatusLabel,
    TableToolbar,
    Pagination,
  },

  setup() {
    const { t } = i18n.global;
    const initialFilters = {
      product: {
        sku: undefined,
        name: undefined,
      },
      user_id: undefined,
      event_id: undefined,
      reserved_from: undefined,
      reserved_to: undefined,
      reserved_qty_from: undefined,
      reserved_qty_to: undefined,
      status_id: undefined,
      is_overbooked: undefined,
    };
    const { filters, clearFilters } = useFilters(
      "product-reservation",
      initialFilters
    );
    const { state, fetch, pagination, removeItem } = useList(
      ProductReservationResource
    );
    const { getLoggedInUser } = useLoggedInUser();
    const list = toRefs(state);
    const totals = reactive({
      product_price: 0,
    });

    function submitFilters() {
      fetch({ page: 1, filters: filters.value });
    }

    function resetFilters() {
      clearFilters();
      fetch({ filters: undefined });
    }

    function exportToExcel() {
      ProductReservationResource.exportToExcel({ filters: filters.value });
    }

    function exportToPdf(type: string) {
      ProductReservationResource.exportToPdf({ type, filters: filters.value });
    }

    function confirmReservation(reservation: ProductReservationModel) {
      if (confirm(t("product_reservation.CONFIRM_CONFIRMATION"))) {
        ProductReservationResource.confirmReservation(reservation.id).then(() =>
          window.location.reload()
        );
      }
    }

    function canConfirmReservation(reservation: ProductReservationModel) {
      if (
        reservation.status_id !== ProductReservationStatusEnum.STATUS_CONFIRMED
      ) {
        return getLoggedInUser().role.id === UserRoleEnum.ROLE_ADMIN;
      }

      return false;
    }

    watch(list.items, (items) => {
      totals.product_price = 0;

      items.map((item) => {
        totals.product_price += item.row_total;
      });
    });

    onMounted(() => {
      fetch({
        expand: "event,product,product.categories,product.inventory,user",
        filters: filters.value,
      });
    });

    return {
      filters,
      resetFilters,
      submitFilters,
      items: list.items,
      totals,
      pagination,
      removeItem,
      exportToExcel,
      exportToPdf,
      confirmReservation,
      canConfirmReservation,
    };
  },
});
